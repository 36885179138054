const {ApiSideNavItems} = require('../ApiSideNavItems.ts');
const DataApiPriority = ApiSideNavItems['DataAPI'];
const ManagementApiPriority = ApiSideNavItems['ManagementAPI'];
const { useFlags } = require('gatsby-plugin-launchdarkly');
const {SDKSideNavPriority} = require('../SDKSideNavPriority.ts');

const getBasePath = (basePath) => {
  if (basePath === '/') {
    return '';
  } else {
    return basePath;
  }
};
const checkIsUnDocumented = (tags) => {
  if (tags) {
    for (const element of tags) {
      if (element === 'Undocumented') {
        return false;
      }
    }
  }
  return true;
};
const getResponse = (response) => {
  if (response.content) {
    const type = Object.keys(response.content)[0];
    return response.content[type];
  } else {
    return response;
  }
};

const constructResponseObj = (responseCode, lang, content) => {
  return {
    id: lang + '_' + responseCode,
    title: responseCode,
    content: content,
  };
};
const parseCode = (code) => {
  return JSON.stringify(code, undefined, 2);
};
const constructType = (type, property, apiName) => {
  if (type === 'object') {
    let obj = {};
    const properties = property.properties;
    if (properties) {
      Object.entries(properties).map(([key, value]) => {
        obj[key] = constructType(value.type, value);
      });
    }
    const additionalProps = property.additionalProperties;
    if (additionalProps) {
      const type = constructType(additionalProps.type, additionalProps);
      obj = {
        ...obj,
        additionalProp1: type,
        additionalProp2: type,
        additionalProp3: type,
      };
    }
    const example = property.example;
    if (example) {
      obj = example;
      if(apiName === 'Upload File')
      {
        const uploadFileObj = {
          'skyflow_id': example['skyflow_id']
        };
        obj = uploadFileObj;
      }
    }
    return obj;
  } else if (type === 'array') {
    const properties = property.items;
    const obj = constructType(properties.type, properties);
    return [obj];
  } else {
    return type;
  }
};

const constructResponses = (responses, apiName) => {
  const responseList = [];
  Object.entries(responses).map(([respCode, value]) => {
    const responseData = getResponse(responses[respCode]);
    if (responseData && responseData.schema) {
      const type = responseData.schema.type;
      if (type === 'object') {
        const obj = parseCode(constructType(type, responseData.schema, apiName));
        responseList.push(constructResponseObj(respCode, 'json', obj));
      } else if (type === 'array') {
        const obj = parseCode(constructType(type, responseData.schema));
        responseList.push(constructResponseObj(respCode, 'json', obj));
      } else if (type === 'string') {
        responseList.push(constructResponseObj(respCode, 'javascript', type));
      }
    } else {
      const obj = {};
      Object.entries(responses[respCode]).map(([key, value]) => {
        obj[key] = value;
      });
      responseList.push(constructResponseObj(respCode, 'json', parseCode(obj)));
    }
  });
  return responseList;
};

function isNumberString(value) {
  if (typeof value === 'string') {
    return !isNaN(parseFloat(value));
  } else {
    return false;
  }
}

function sortObjectByPriority(obj, apiType) {
  const keys = apiType === 'record'
    ? Object.keys(obj).sort((a, b) => {
      if (DataApiPriority[a] !== DataApiPriority[b]) {
        if(!DataApiPriority[a] === true && !DataApiPriority[b] === true)
        {
          return a.toLowerCase().localeCompare(b.toLowerCase());
        }
        return (
          (!DataApiPriority[a] === true ? Infinity : DataApiPriority[a]) -
          (!DataApiPriority[b] === true ? Infinity : DataApiPriority[b])
        );
      } else {
        return a.toLowerCase().localeCompare(b.toLowerCase());
      }
    })
    : Object.keys(obj).sort((a, b) => {
      if (ManagementApiPriority[a] !== ManagementApiPriority[b]) {
        if(!ManagementApiPriority[a] === true && !ManagementApiPriority[b] === true)
        {
          return a.toLowerCase().localeCompare(b.toLowerCase());
        }
        return (
          (!ManagementApiPriority[a] === true ? Infinity : ManagementApiPriority[a]) -
          (!ManagementApiPriority[b] === true ? Infinity : ManagementApiPriority[b])
        );
      } else {
        return a.toLowerCase().localeCompare(b.toLowerCase());
      }
    });

  const sortedObj = {};
  for (const key of keys) {
    sortedObj[key] = obj[key].sort((a, b) => {
      if (a.tags[1] !== b.tags[1]) {
        if(!a.tags[1] === true && !b.tags[1] === true)
        {
          return a.summary.toLowerCase().localeCompare(b.summary.toLowerCase());
        }
        return (
          (!a.tags[1] === true ? Infinity : parseInt(a.tags[1])) -
          (!b.tags[1] === true ? Infinity : parseInt(b.tags[1]))
        );
      } else {
        return a.summary.toLowerCase().localeCompare(b.summary.toLowerCase());
      }
    });
  }

  return sortedObj;
}

const updateForBinaryFormat = (paths) => {
  paths.forEach(path => {
    if(path.requestBody && path.requestBody.content['multipart/form-data'])
    {
      const properties = path.requestBody.content['multipart/form-data'].schema.properties;
      let curl = path.snippets[0].content;
      const headerIndex = curl.indexOf('--header', curl.indexOf('header') + 1);
      path.snippets[0].content = curl.slice(0,headerIndex) + curl.slice(curl.indexOf('--form'));
      if(!Array.isArray(properties))
      {
        Object.keys(properties).forEach(key => {
          if(properties[key].format === 'binary')
          {
            curl = path.snippets[0].content;
            if(curl.includes(key))
            {
              const index =  curl.indexOf(key, curl.indexOf('--form'));
              const tempIndex = index+key.length+7;
              path.snippets[0].content = curl.slice(0,index)+ `{${key}}=@{filePath}` + curl.slice(tempIndex);
            }
          }
        });
      }
    }
  });
};

const getSideNavList = (apiJson, apiType) => {
  const pathData = apiJson.paths;
  const tagData = {};
  const sideNavList = [];

  for (const path in pathData) {
    const paths = pathData[path];
    for (const method in paths) {
      if (paths[method] && checkIsUnDocumented(paths[method].tags)) {
        if (paths[method].tags) {
          paths[method].tags.forEach((tag) => {
            if (!isNumberString(tag) && tag !== '') {
              let list = [];
              if (tagData[tag]) {
                list = [...tagData[tag]];
              }
              const obj = {
                ...paths[method],
                method: method,
                path: path,
              };
              list.push(obj);
              tagData[tag] = list;
            }
          });
        } else {
          const tag = paths[method].summary;
          let list = [];
          if (tagData[tag]) {
            list = [...tagData[tag]];
          }
          const obj = {
            ...paths[method],
            method: method,
            path: path,
          };
          list.push(obj);
          tagData[tag] = list;
        }
      }
    }
  }

  sideNavList.push({
    label: 'Back',
    route: 'back',
  });
  const sortedTagData = sortObjectByPriority(tagData, apiType);
  Object.keys(sortedTagData).map((key) => {
    const value = sortedTagData[key];
    const tagList = [];
    value.map((data) => {
      tagList.push({
        label: data.summary,
        method: data.method,
        route: '#' + data.operationId,
        'x-enable': data['x-enable'],
      });
    });
    sideNavList.push({
      child: tagList,
      label: key,
      route: '#' + key,
    });
  });

  return sideNavList;
};

const filterSnippets = (snippets, nonStandardAuth) => {
  return snippets.map((snippet) => {
    if (snippet.id === "shell_curl") {
      let content = snippet.content;

      const prettifyJsonPayload = (payload) => {
        try {
          const parsedPayload = JSON.parse(payload);
          return JSON.stringify(parsedPayload, null, 2);
        } catch (error) {
          return payload;
        }
      };

      const modifyUrlPart = (url) => {
        const [baseUrl, queryString] = url.split('?');
        if (!queryString) {
          return baseUrl;
        }
        const params = queryString.split('&');
        const validParams = [];
        const placeholderPattern = /^SOME_.*_VALUE$/;
        
        params.forEach(param => {
          const [key, value] = param.split('=');
          if (value && !placeholderPattern.test(value)) {
            validParams.push(`${key}=${value}`);
          }
        });
        
        return validParams.length ? `${baseUrl}?${validParams.join('&')}` : baseUrl;
      };
      const urlMatch = content.match(/--url '([^']+)'/);
      if (urlMatch) {
        const originalUrl = urlMatch[1];
        const modifiedUrl = modifyUrlPart(originalUrl);
        snippet.content = content.replace(originalUrl, modifiedUrl);
      }

      snippet.content = snippet.content
      .replace(/--header/gi, '-H')
      .replace(/--request/gi, '-x')
      .replace(/--data/gi, '-d')
      .replace(/--url /gi, '')
      .replace(/--location/gi, '')
      .replace(/\\?\s+$/, '')
      .trim(); 

      const dataMatch = snippet.content.match(/(-d ')(.*)(')/s);
      if (dataMatch && dataMatch[2]) {
        const originalPayload = dataMatch[2];
        const prettifiedPayload = prettifyJsonPayload(originalPayload);
        snippet.content = snippet.content.replace(originalPayload, prettifiedPayload);
      }

      snippet.content = snippet.content.replace(
        /(--header|-H) 'content-type: ([^']+)'/i,
        "$1 'Content-Type: $2'"
      );
      snippet.content = snippet.content.replace(
        /(--header|-H) 'Authorization: ([^']+)'/i,
        "$1 'Authorization: Bearer $TOKEN'"
      );
      if (nonStandardAuth) {
        snippet.content = snippet.content.replace(
          /(--header|-H) 'Authorization: [^']+'\s*\\?\s*/i,
          ''
        ).trim();
      } 
    }
    return snippet;
  });
};

const getApiData = (apiJson, snippets, apiType) => {
  const pathData = apiJson.paths;
  const tagData = {};

  let baseUrl = '';
  if (apiJson.servers) {
    baseUrl = apiJson.servers[0].url;
  } else {
    apiJson.host = apiJson.host ? apiJson.host : 'example.com';
    apiJson.schemes = apiJson.schemes ? apiJson.schemes : ['https'];
    apiJson.basePath = apiJson.basePath ? apiJson.basePath : 'undefined';
    baseUrl =
      apiJson.schemes[0] + '://' + apiJson.host + getBasePath(apiJson.basePath);
  }
  const tempUrl = 'http://undefined' + apiJson.basePath;
  snippets.forEach((snips) => {
    let pathStr = snips.url.replace(baseUrl, '');
    pathStr = pathStr.replace(tempUrl, '');
    const mtd = snips.method.toLowerCase();
    delete snips.description;
    snips.snippets[0].content = decodeURIComponent(snips.snippets[0].content);
    pathData[pathStr][mtd] = { ...pathData[pathStr][mtd], ...snips };
  });

  for (const path in pathData) {
    const paths = pathData[path];
    for (const method in paths) {
      if (paths[method] && checkIsUnDocumented(paths[method].tags)) {
        if (paths[method].tags) {
          paths[method].tags.forEach((tag) => {
            if (!isNumberString(tag) && tag !== '') {
              let list = [];
              if (tagData[tag]) {
                list = [...tagData[tag]];
              }
              const responseList = paths[method].responses;
              const obj = {
                ...paths[method],
                method: method,
                path: path,
                responseList: constructResponses(responseList, paths[method].summary),
              };
              list.push(obj);
              tagData[tag] = list;
            }
          });
        } else {
          const tag = paths[method].summary;
          let list = [];
          if (tagData[tag]) {
            list = [...tagData[tag]];
          }
          const responseList = paths[method].responses;
          const obj = {
            ...paths[method],
            method: method,
            path: path,
            responseList: constructResponses(responseList),
          };
          list.push(obj);
          tagData[tag] = list;
        }
      }
    }
  }

  const sortedTagData = sortObjectByPriority(tagData, apiType);
  Object.keys(sortedTagData).map((key) => {
    updateForBinaryFormat(sortedTagData[key]);
  });
  return sortedTagData;
};

const checkEnv = (pathData) => {
  const enable = pathData['x-enable'];
  const flags = useFlags();
  if(enable)
  {
    let envEnabled = false;
    if(flags[enable])
    {
      envEnabled = true;
    }
    return envEnabled;
  }
  else{
    return true;
  } 
};

const sortSDKNavItems = (navData, sdkName) => {
  if(SDKSideNavPriority[sdkName])
  {
    const sdkPriority = SDKSideNavPriority[sdkName];
    const keys = navData.sort((a, b) => {
      if (sdkPriority[a.label]?.order !== sdkPriority[b.label]?.order) {
        if(!sdkPriority[a.label]?.order === true && !sdkPriority[b.label]?.order === true)
        {
          return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
        }
        return (
          (!sdkPriority[a.label]?.order === true ? Infinity : sdkPriority[a.label]?.order) -
          (!sdkPriority[b.label]?.order === true ? Infinity : sdkPriority[b.label]?.order)
        );
      } else {
        return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
      }
    });

    const sortedObj = [];
    for (const key of keys) {
      const childItemsPriority = sdkPriority[key.label]?.child;
      if(key.child && childItemsPriority)
      {
        key.child.sort((a, b) => {
          if (childItemsPriority[a.label]?.order !== childItemsPriority[b.label]?.order) {
            if(!childItemsPriority[a.label]?.order === true && !childItemsPriority[b.label]?.order === true)
            {
              return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
            }
            return (
              (!childItemsPriority[a.label]?.order === true ? Infinity : childItemsPriority[a.label]?.order) -
              (!childItemsPriority[b.label]?.order === true ? Infinity : childItemsPriority[b.label]?.order)
            );
          } else {
            return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
          }
        });
        sortedObj.push(key);
      }
      else {
        sortedObj.push(key);
      }
    }
    return sortedObj;
  }
  return navData;
};

module.exports = { getApiData, getSideNavList, checkEnv, sortSDKNavItems, filterSnippets, getBasePath, isNumberString, sortObjectByPriority };
